.select {}

.select__list {
  --anchor-gap: 0.25rem;
  width: var(--button-width);
  transition: opacity 0.1s ease-in;
  opacity: 1;
}

.select__list[data-closed] {
  opacity: 0;
}

.select__option {
  cursor: pointer;
}
.select__option[data-focus] {
  background-color: var(--bs-gray-200);
}
.select__option[data-selected] .select__selected-icon {
  opacity: 1;
}
.select__selected-icon {
  opacity: 0;
}

.preview-modal-container {
  position: relative;
  width: 520px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 8px;
  text-align: center;
}

.preview-modal-title {
  color: #000;
  font-size: 28px;
  font-weight: 700;
}
.preview-modal-subtitle {
  font-size: 18px;
  color: #000;
  margin-top: 4px;
}
.preview-modal-description {
  font-size: 14px;
  color: grey;
  margin-top: 10px;
}

.preview-modal-buttons {
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-top: 30px;
  max-width: 300px;
  margin-inline: auto;
}
.preview-modal-cta-button {
  background-color: black;
  color: #fff;
  font-weight: 700;
  border-radius: 0;
}
.preview-modal-cta-button:hover {
  background-color: white;
  color: #000;
}
.preview-modal-dismiss-button {
  background-color: white;
  color: #000;
  font-weight: 700;
  border-radius: 0;
}
.preview-modal-dismiss-button:hover {
  background-color: black;
  color: #fff;
}

.preview-modal-close-button {
  background-color: transparent;
  border: none;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  color: #000;
}
:root {
  --spacing-0: 0rem;
  --spacing-4: 1rem;
  
  --durations-fast: 150ms;
}

.toast-viewport {
  position: fixed;
  top: 0;
  z-index: 10000;
  display: flex;
  gap: .65rem;
  max-height: 100vh;
  width: 100%;
  flex-direction: column-reverse;
  padding: 1rem;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .toast-viewport {
    bottom: 0;
    right: 0;
    top: auto;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .toast-viewport {
    max-width: 420px;
  }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

.toast-root {
  --toast-text-color: var(--bs-black);
  --toast-bg-color: var(--bs-white);
  --toast-close-btn-color: var(--bs-gray-700);

  pointer-events: auto;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  overflow: hidden;
  border-radius: 4px;
  padding: 1.15rem;
  padding-right: 2rem;
  box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
  transition-property: all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration: .15s;
  
  border: 1px solid #e4e4e7;
  background: var(--toast-bg-color);
  color: var(--toast-text-color);
}

.toast-root:hover .toast-close-btn {
  opacity: .6;
}
.toast-root:hover .toast-close-btn:hover {
  opacity: 1;
}

.toast-root--success {
  --toast-text-color: var(--bs-white);
  --toast-bg-color: hsl(146, 79%, 44%);
  --toast-border-color: hsl(146, 79%, 44%);
  --toast-close-btn-color: var(--bs-white);

  border: 1px solid var(--toast-border-color);
  background: var(--toast-bg-color);
  color: var(--toast-text-color);
}

.toast-root--error {
  --toast-text-color: var(--bs-white);
  --toast-bg-color: var(--bs-danger);
  --toast-border-color: var(--bs-danger);
  --toast-close-btn-color: var(--bs-white);

  border: 1px solid var(--toast-border-color);
  background: var(--toast-bg-color);
  color: var(--toast-text-color);
}

.toast-root--warning {
  --toast-text-color: var(--bs-white);
  --toast-bg-color: hsl(45, 82%, 57%);
  --toast-border-color: hsl(45, 82%, 57%);
  --toast-close-btn-color: var(--bs-white);

  border: 1px solid var(--toast-border-color);
  background: var(--toast-bg-color);
  color: var(--toast-text-color);
}

.toast-root--info {
  --toast-text-color: var(--bs-white);
  --toast-bg-color: var(--bs-info);
  --toast-border-color: var(--bs-info);
  --toast-close-btn-color: var(--bs-white);

  border: 1px solid var(--toast-border-color);
  background: var(--toast-bg-color);
  color: var(--toast-text-color);
}

.toast-root[data-swipe=cancel] {
  transform:translateX(0)
}
.toast-root[data-swipe=end] {
  transform:translateX(var(--radix-toast-swipe-end-x));
  animation-name:exit;
  animation-duration:var(--durations-fast);
  --shadow-panda-exit-opacity:1;
  --shadow-panda-exit-scale:1;
  --shadow-panda-exit-rotate:0;
  --shadow-panda-exit-translate-x:0;
  --shadow-panda-exit-translate-y:0
}
.toast-root[data-swipe=move] {
  transform:translateX(var(--radix-toast-swipe-move-x));
  transition:none
}
.toast-root[data-state=open] {
  animation-name: enter;
  animation-duration:var(--durations-fast);
  --shadow-panda-enter-opacity:1;
  --shadow-panda-enter-scale:1;
  --shadow-panda-enter-rotate:0;
  --shadow-panda-enter-translate-x:0;
  --shadow-panda-enter-translate-y:calc(100% * -1)
}
.toast-root[data-state=closed] {
  animation-name: exit;
  animation-duration:var(--durations-fast);
  --shadow-panda-exit-opacity:0.8;
  --shadow-panda-exit-scale:1;
  --shadow-panda-exit-rotate:0;
  --shadow-panda-exit-translate-x:100%;
  --shadow-panda-exit-translate-y:0
}

@media (min-width: 576px) {
  .toast-root[data-state="open"] {
    --shadow-panda-enter-translate-y: 100%;
  }
}

@media (min-width: 768px) {
  .toast-root {
    
  }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

.toast-title {
  margin-bottom: 0.25rem;
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.15rem;
}

.toast-close-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  background: none;
  border: none;
  opacity: 0;
  display: inline-flex;
  padding: .25rem;
  color: var(--toast-close-btn-color);
  transition: opacity .1s, color .1s;
  border-radius: 4px;
  
}

.toast-close-btn:focus-visible {
  outline: 2px solid var(--bs-primary-border-subtle);
  outline-offset: 2px;
}

@keyframes enter {
  0% {
    opacity: var(--shadow-panda-enter-opacity,1);
    transform:
      translate3d(var(--shadow-panda-enter-translate-x,0),var(--shadow-panda-enter-translate-y,0),0)
      scale3d(var(--shadow-panda-enter-scale,1),var(--shadow-panda-enter-scale,1),var(--shadow-panda-enter-scale,1))
      rotate(var(--shadow-panda-enter-rotate,0));
  }
}

@keyframes exit {
  to {
    opacity:var(--shadow-panda-exit-opacity,1);
    transform:
      translate3d(var(--shadow-panda-exit-translate-x,0),var(--shadow-panda-exit-translate-y,0),0)
      scale3d(var(--shadow-panda-exit-scale,1),var(--shadow-panda-exit-scale,1),var(--shadow-panda-exit-scale,1))
      rotate(var(--shadow-panda-exit-rotate,0))
  }
}


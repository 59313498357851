.bank-card {
  position: relative;
  height: 240px;
  transition: none !important;
}

.bank-card--default {
  box-shadow: 0 0 0px 4px #fff, 0 0 0px 8px var(--bs-success) !important;
}
.bank-card--default:before {
  content: attr(data-default-text);
  background-color: var(--bs-success);
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #fff;
  border-radius: 0.75rem;
  padding: 0.15rem 0.5rem;
  font-size: .75rem;
}

.bank-card:hover .bank-card__delete-btn {
    opacity: 1;
}

.bank-card__delete-btn {
  opacity: 0;
}
.lp-tooltip__trigger-btn {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: hsl(0, 0%, 62%);
  cursor: default;
}

.lp-tooltip__body {
  z-index: 1500;
  pointer-events: auto;
  max-width: 300px;
  padding: .25rem .5rem;
  background-color: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 100%);
  border-radius: 4px;
  font-size: 0.76rem;
  opacity: .9;
  animation: .2s fadeIn09;
}

@keyframes fadeIn09 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .9;
  }
}
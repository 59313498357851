.panel {
  display: block;
}

.panel--open .panel__body {
  max-height: 100%;
  padding-block: 1rem;
}
.panel--open .panel__toggle-btn svg {
  transform: rotate(90deg);
}
.panel--animation .panel__body {
  transform: translateY(0);
  opacity: 1;
}

.panel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.panel__body {
  overflow: hidden;
  padding-inline: .25rem;
  opacity: 0;
  transform: translateY(4%);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  max-height: 0;
}

.panel__toggle-btn {
  display: inline-block;
  appearance: none;
  background-color: transparent;
  user-select: auto;
  cursor: pointer;
  padding: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out
}
.panel__toggle-btn svg {
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out
}
.panel__toggle-btn:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}